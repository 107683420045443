.form_auth_main {
  box-shadow: rgb(0 0 0 / 15%) 0px 1px 5px;
  padding: 5px;
  width: 60%;
  margin: 0 auto;
}

a:hover {
  color: rgb(76, 39, 179);
}

.auth_error_success {
  font-size: 15px;
}

.auth_verify_text {
  font-size: 17px;
}

.auth_member_img {
  width: 150px;
  border-radius: 100px;
  margin-bottom: 20px;
  margin-top: 20px;
  object-fit: contain;
}

.auth_link {
  color: #06b6d4;
  cursor: pointer;
}
.auth_link:hover {
  color: #1890ff;
}

.auth_label {
  font-size: 17px;
  margin: 10px 0;
}

.auth_input {
  font-size: 17px;
  padding: 10px 0px;
}

.auth_btn_reg {
  font-size: 20px !important;
  height: 47px !important;
  border-radius: 50px;
  background-color: #38d39f !important;
  border-color: #38d39f !important;
  width: 100%;
  color: #fff !important;
}
.auth_btn_reg:hover {
  background-color: #1890ff !important;
  border-color: #1890ff !important;
}

.otp_gen_btn {
  font-size: 14px !important;
  background-color: #38d39f !important;
  border-color: #38d39f !important;
}

.upload_img_btn {
  padding: 10px;
  border-radius: 3px;
  cursor: pointer;
}

.upload_dis_img_btn {
  background-color: #ddd;
  padding: 10px;
  border-radius: 3px;
  cursor: none;
}

.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex-container > input {
  margin: 10px;
  padding: 3%;
  font-size: 22px;
  text-align: center;
}

.vcode_input {
  font-size: 22px !important;
  background-image: none !important;
}

.vcode_input::placeholder {
  font: 22px/3 sans-serif;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

/*  */

.bg_page {
  background-color: 'rgb(76, 39, 179)';
}

.formInput {
  display: flex;
  flex-direction: column;
}

.error_form {
  font-size: 13px;
  color: #faa0a0;
  display: none;
  margin-top: 3px;
}

/* input:invalid[focused='true'] {
  border: 1px solid red;
} */

input:invalid[focused='true'] ~ span {
  display: block;
}

/* Not Found */

/* / Not Found Page / */
#notfound {
  position: relative;
  height: 50vh;
}
#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.notfound {
  max-width: 520px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
}
.notfound .notfound-404 {
  position: relative;
  height: 200px;
  margin: 0px auto 20px;
  z-index: -1;
}
.notfound .notfound-404 h1 {
  font-size: 236px;
  font-weight: 200;
  margin: 0px;
  color: #00bbf9;
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.notfound .notfound-404 h2 {
  font-size: 28px;
  font-weight: 400;
  text-transform: uppercase;
  color: #00bbf9;
  background: rgba(255, 255, 255, 0.9);
  padding: 10px 5px;
  margin: auto;
  display: inline-block;
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
}
.notfound a {
  display: inline-block;
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  padding: 8px 23px;
  background: rgb(226, 4, 89);
  font-size: 18px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}
.notfound a:hover {
  color: #fff;
  background: #00bbf9;
}

.react-autosuggest__suggestions-list {
  position: absolute;
  z-index: 1;
  margin: 5px 0;
  width: 320px;
}

.react-autosuggest__suggestion {
  background-color: white;
  color: rgb(61, 61, 61);
  cursor: pointer;
  font-weight: bold;
  padding: 10px;
}
.react-autosuggest__suggestion:hover {
  background-color: #1890ff;
  color: #fff;
}
