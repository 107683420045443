@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Raleway';
  src: url('./assets//fonts/Raleway-VariableFont_wght.ttf') format('truetype');
}

body {
  background-color: #fff;
  font-family: 'Raleway', sans-serif;
}

.sidebar_color {
  background: #0e86d4;
}

.link_dash {
  background-color: #004a93;
}

.link_dash:hover {
  color: inherit !important;
  background-color: rgb(64, 92, 251);
}
.link_dash_act:hover {
  color: #fff !important;
  background-color: rgb(64, 58, 237);
}

.new_role_btn {
  background-color: #004a93;
  border-radius: 4px;
  padding: 5px 20px;
  font-size: 15px;
  height: 40px;
  color: #fff;
}

.custom_design {
  font-family: Helvetica, sans-serif !important;
}
.text_color_new {
  color: #28abe2;
}
.bg_color_new {
  background: #28abe2;
}
